<template>
  <div class="container-fluid">

    <div class="consent-form">
      <div class="note-wrap">
          <div class="letter-title">
            同意書
          </div>
        </div>
      <ValidationObserver tag="form" v-slot="{ handleSubmit }">
        <div class="info-wrap">
            <div class="field-wrap">
                <div class="form-group">


                    <div class="header">
                        <label>
                            <span class="mark">*</span>
                            標題
                        </label>
                    
                        <section>
                            <div tabindex="0">
                                <button
                                    class="tab"
                                    :class="{ focus: titleTabs === 'zh' }"
                                    @click.prevent="titleTabs = 'zh'"
                                >
                                    中文
                                </button>
                                </div>
                                <div tabindex="0">
                                <button
                                    class="tab"
                                    :class="{ focus: titleTabs === 'en' }"
                                    @click.prevent="titleTabs = 'en'"
                                >
                                    英文
                                </button>
                            </div>
                        </section>
                    </div>

                    <input type="text" maxlength="50" class="form-control" v-show="titleTabs === 'zh'" v-model.trim="TitleChinese"/>
                    <input type="text" maxlength="100" class="form-control" v-show="titleTabs === 'en'" v-model.trim="TitleEnglish"/>

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <input type="text" maxlength="50" class="form-control" v-show="false" v-model.trim="TitleChinese"/>
                        <span v-if="errors[0]" class="text-danger">
                        中文標題 {{ errors[0] }}
                        </span>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                    >

                        <input type="text" maxlength="100" class="form-control" v-show="false" v-model.trim="TitleEnglish"/>
                        <span v-if="errors[0]" class="text-danger">
                        英文標題 {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                </div>
                <div class="form-group">


                    <div class="header">
                        <label>
                            <span class="mark">*</span>
                            {{ i18n("Template.Content") }}
                        </label>
                        <section>
                            <div tabindex="0">
                                <button
                                    class="tab"
                                    :class="{ focus: contentTabs === 'zh' }"
                                    @click.prevent="contentTabs = 'zh'"
                                >
                                    中文
                                </button>
                                </div>
                                <div tabindex="0">
                                <button
                                    class="tab"
                                    :class="{ focus: contentTabs === 'en' }"
                                    @click.prevent="contentTabs = 'en'"
                                >
                                    英文
                                </button>
                            </div>
                        </section>
                    </div>


                    <VueEditor
                        style="background: rgb(255, 255, 255);"
                        v-show="contentTabs === 'zh'"
                        v-model="HtmlChinese"
                    />

                    <VueEditor
                        style="background: rgb(255, 255, 255);"
                        v-show="contentTabs === 'en'"
                        v-model="HtmlEnglish"
                    />

                    <ValidationProvider
                        :name="i18n('Template.Content')"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <VueEditor
                            style="background: rgb(255, 255, 255);"
                            v-show="false"
                            v-model="HtmlChinese"
                        />
                        <span v-if="errors[0]" class="text-danger">
                        中文內容 {{ errors[0] }}
                        </span>
                    </ValidationProvider>

                    <ValidationProvider
                        :name="i18n('Template.Content')"
                        rules="required"
                        v-slot="{ errors }"
                    >

                        <VueEditor
                            style="background: rgb(255, 255, 255);"
                            v-show="false"
                            v-model="HtmlEnglish"
                        />
                        <span v-if="errors[0]" class="text-danger">
                        英文內容 {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                </div>
            </div>
        </div>

        <div class="button-wrap center">
          <button
            type="button"
            class="btn btn-success"
            style="margin-left: 5px"
            @click="handleSubmit(saveData)"
          >
            {{ i18n("Buttons.Save") }}
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { apiGetPersonalHealthAgreeDesign, apiSavePersonalHealthAgreeDesign } from '@/apis/template'
export default {
    name: "ConsentForm",

    data() {
        return {
            TitleChinese: null,
            TitleEnglish: null,
            HtmlChinese: null,
            HtmlEnglish: null,

            titleTabs: 'zh',
            contentTabs: 'zh',
        };
    },

    methods: {
        ///////////////////////////  多國語系  ///////////////////////////////////////////
        i18n(keyStr) {
            return this.$t(keyStr);
        },

        // 取得個資聲明
        getConsentForm() {
            const vm = this;

            vm.$store.dispatch("updateLoading", true);
            apiGetPersonalHealthAgreeDesign()
                .then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        vm.$store.dispatch("updateLoading", false);
                        vm.TitleChinese = response.data.TitleChinese
                        vm.TitleEnglish = response.data.TitleEnglish
                        vm.HtmlChinese = response.data.HtmlChinese
                        vm.HtmlEnglish = response.data.HtmlEnglish
                    }
                })
                .catch((error) => {
                    vm.$store.dispatch("updateLoading", false);
                    vm.$notify({
                        title: "失敗",
                        message: error.response.data,
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        // 編輯版本
        saveData() {
            const vm = this;

            const data = {
                TitleChinese: vm.TitleChinese,
                TitleEnglish: vm.TitleEnglish,
                HtmlChinese: vm.HtmlChinese,
                HtmlEnglish: vm.HtmlEnglish,
                SavedUser: vm.$cookies.get("userId"),
            };

            vm.$store.dispatch("updateLoading", true);
            apiSavePersonalHealthAgreeDesign({
                ...data
            }).then((response) => {
                    if (response.status === 200) {
                        vm.$store.dispatch("updateLoading", false);
                        vm.$notify({
                            title: "成功",
                            message: "編輯成功",
                            type: "success",
                            duration: 2000,
                        });
                    }
                })
                .catch((error) => {
                    vm.$store.dispatch("updateLoading", false);
                    vm.$notify({
                        title: "失敗",
                        message: error.response.data,
                        type: "error",
                        duration: 2000,
                    });
                });
        },

    },

    created() {
        this.getConsentForm();
    },
};
</script>


<style lang="scss" scoped>
@import '@/assets/scss/_tabs.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section {
    text-align: right;
}

.button-wrap.center {
    text-align: center;
}

</style>

