var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"consent-form"},[_vm._m(0),_c('ValidationObserver',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"info-wrap"},[_c('div',{staticClass:"field-wrap"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"header"},[_c('label',[_c('span',{staticClass:"mark"},[_vm._v("*")]),_vm._v(" 標題 ")]),_c('section',[_c('div',{attrs:{"tabindex":"0"}},[_c('button',{staticClass:"tab",class:{ focus: _vm.titleTabs === 'zh' },on:{"click":function($event){$event.preventDefault();_vm.titleTabs = 'zh'}}},[_vm._v(" 中文 ")])]),_c('div',{attrs:{"tabindex":"0"}},[_c('button',{staticClass:"tab",class:{ focus: _vm.titleTabs === 'en' },on:{"click":function($event){$event.preventDefault();_vm.titleTabs = 'en'}}},[_vm._v(" 英文 ")])])])]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.titleTabs === 'zh'),expression:"titleTabs === 'zh'"},{name:"model",rawName:"v-model.trim",value:(_vm.TitleChinese),expression:"TitleChinese",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","maxlength":"50"},domProps:{"value":(_vm.TitleChinese)},on:{"input":function($event){if($event.target.composing){ return; }_vm.TitleChinese=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.titleTabs === 'en'),expression:"titleTabs === 'en'"},{name:"model",rawName:"v-model.trim",value:(_vm.TitleEnglish),expression:"TitleEnglish",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.TitleEnglish)},on:{"input":function($event){if($event.target.composing){ return; }_vm.TitleEnglish=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"model",rawName:"v-model.trim",value:(_vm.TitleChinese),expression:"TitleChinese",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","maxlength":"50"},domProps:{"value":(_vm.TitleChinese)},on:{"input":function($event){if($event.target.composing){ return; }_vm.TitleChinese=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" 中文標題 "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"model",rawName:"v-model.trim",value:(_vm.TitleEnglish),expression:"TitleEnglish",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.TitleEnglish)},on:{"input":function($event){if($event.target.composing){ return; }_vm.TitleEnglish=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" 英文標題 "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"header"},[_c('label',[_c('span',{staticClass:"mark"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.i18n("Template.Content"))+" ")]),_c('section',[_c('div',{attrs:{"tabindex":"0"}},[_c('button',{staticClass:"tab",class:{ focus: _vm.contentTabs === 'zh' },on:{"click":function($event){$event.preventDefault();_vm.contentTabs = 'zh'}}},[_vm._v(" 中文 ")])]),_c('div',{attrs:{"tabindex":"0"}},[_c('button',{staticClass:"tab",class:{ focus: _vm.contentTabs === 'en' },on:{"click":function($event){$event.preventDefault();_vm.contentTabs = 'en'}}},[_vm._v(" 英文 ")])])])]),_c('VueEditor',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentTabs === 'zh'),expression:"contentTabs === 'zh'"}],staticStyle:{"background":"rgb(255, 255, 255)"},model:{value:(_vm.HtmlChinese),callback:function ($$v) {_vm.HtmlChinese=$$v},expression:"HtmlChinese"}}),_c('VueEditor',{directives:[{name:"show",rawName:"v-show",value:(_vm.contentTabs === 'en'),expression:"contentTabs === 'en'"}],staticStyle:{"background":"rgb(255, 255, 255)"},model:{value:(_vm.HtmlEnglish),callback:function ($$v) {_vm.HtmlEnglish=$$v},expression:"HtmlEnglish"}}),_c('ValidationProvider',{attrs:{"name":_vm.i18n('Template.Content'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VueEditor',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticStyle:{"background":"rgb(255, 255, 255)"},model:{value:(_vm.HtmlChinese),callback:function ($$v) {_vm.HtmlChinese=$$v},expression:"HtmlChinese"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" 中文內容 "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.i18n('Template.Content'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VueEditor',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticStyle:{"background":"rgb(255, 255, 255)"},model:{value:(_vm.HtmlEnglish),callback:function ($$v) {_vm.HtmlEnglish=$$v},expression:"HtmlEnglish"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" 英文內容 "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"button-wrap center"},[_c('button',{staticClass:"btn btn-success",staticStyle:{"margin-left":"5px"},attrs:{"type":"button"},on:{"click":function($event){return handleSubmit(_vm.saveData)}}},[_vm._v(" "+_vm._s(_vm.i18n("Buttons.Save"))+" ")])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"note-wrap"},[_c('div',{staticClass:"letter-title"},[_vm._v(" 同意書 ")])])}]

export { render, staticRenderFns }