


import req from './https'



//  疫調同意書設計
export const apiGetPersonalHealthAgreeDesign = (params) => req('get', '/Questionnire/GetPersonalHealthAgreeDesign', params)
export const apiSavePersonalHealthAgreeDesign = (params) => req('post', '/Questionnire/SavePersonalHealthAgreeDesign', params)




export const apiGetExternalHealthAgreeDesign = (params) => req('get', '/ExternalQnr/GetExternalHealthAgreeDesign', params)
export const apiSaveExternalHealthAgreeDesign = (params) => req('post', '/ExternalQnr/SaveExternalHealthAgreeDesign', params)


